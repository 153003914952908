<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card v-if="file">
        <v-card-title class="headline grey lighten-2">
          Modifica permessi
        </v-card-title>

        <v-card-text>
          <p>
            Stai per modificare gli utenti che potranno accedere al file
            <b>{{ file.displayName }}</b
            >.
          </p>
          <v-autocomplete
            item-value="id"
            item-text="email"
            :loading="usersLoading"
            v-model="selectedUsers"
            :items="users"
            :search-input.sync="updateUsers"
            cache-items
            label="Condividi con"
            clearable
            small-chips
            deletable-chips
            multiple
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="dialog = false"> Annulla </v-btn>
          <v-btn color="primary" text @click="onSaveClick"> Salva </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return { updateUsers: null, usersLoading: false };
  },
  watch: {
    updateUsers: _.debounce(async function (val) {
      val && this.searchusers(val);
    }, 500),
    async file(value) {
      await this.$store.dispatch(
        "filesView/users/updateList",
        value?.sharedWith || []
      );
      this.selectedUsers = _.map(value?.sharedWith || [], (u) => u.id);
    },
  },
  methods: {
    async onSaveClick() {
      console.log(this.selectedUsers);
      await this.$store.dispatch("filesView/saveShareWith", {
        fileId: this.file.id,
        usersIds: this.selectedUsers,
      });
      this.dialog = false;
    },
    async searchusers(q) {
      this.usersLoading = true;
      try {
        await this.$store.dispatch(
          `filesView/users/search`,
          q && typeof q === "string" ? q : null
        );
      } catch (ex) {
        console.error(ex);
      } finally {
        this.usersLoading = false;
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters["filesView/users/list"];
    },
    dialog: {
      get() {
        return this.$store.state.filesView.addUserDialog;
      },
      set(value) {
        this.$store.commit("filesView/setAddUserDialog", value);
      },
    },
    file() {
      return this.$store.getters["filesView/files/current"];
    },
    selectedUsers: {
      get() {
        return this.$store.state.filesView.dialogSelectedUsers;
      },
      set(value) {
        this.$store.commit("filesView/setDialogSelectedUsers", value);
      },
    },
  },
};
</script>

<style>
</style>