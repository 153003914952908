var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ShareWithModal'),_c('DeleteFileModal'),_c('uploadFileForm'),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"md":"9"}},[_c('h3',[_vm._v("Elenco File")])]),_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Cerca","type":"text","name":"searchFilter","hide-details":""},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.tableParams,"server-items-length":_vm.totalItems,"loading":_vm.tableLoading,"headers":_vm.visibleColumns,"items":_vm.files,"sort-by":"createdOn","sort-desc":true},on:{"update:options":function($event){_vm.tableParams=$event},"update:must-sort":_vm.getDataFromApi,"update:items-per-page":_vm.getDataFromApi,"update:page":_vm.getDataFromApi,"update:sort-by":_vm.getDataFromApi,"update:sort-desc":_vm.getDataFromApi},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.fileType(item.displayName)))])]}},{key:"item.downloadURL",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onDownloadClick(item)}}},[_vm._v("Download")])]}},{key:"item.sharedWith",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-3 pb-3"},[_vm._l((item.sharedWith),function(user){return _c('v-chip',{key:user.id,staticClass:"mr-1 mb-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(user.email))])}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onEditUsers(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-pencil-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica utenti")])])],2)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","x-small":"","depressed":"","color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.onRemoveClick(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Rimuovi")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }