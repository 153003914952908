<template>
  <validation-observer ref="observer">
    <v-form enctype="multipart/form-data" class="mb-3">
      <h3>Carica File</h3>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            label="Titolo"
            v-model="titolo"
            type="text"
            class="form-control"
            name="titolo"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <validation-provider
            v-slot="{ errors }"
            name="File"
            rules="required"
            :mode="fileValidation"
          >
            <v-file-input
              v-model="uploadedFiles"
              chips
              multiple
              @change="onFileChange($event)"
              label="Scegli file"
            ></v-file-input>
            <div
              v-for="error in errors"
              :key="error"
              class="alert alert-danger red--text"
              role="alert"
            >
              Selezionare il file da caricare
            </div>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="isUserAdmin">
          <validation-provider
            v-slot="{ errors }"
            name="Condividi con"
            rules="required"
          >
            <!-- Utente -->
            <v-autocomplete
              item-value="id"
              item-text="email"
              :loading="usersLoading"
              v-model="visibleTo"
              :items="users"
              :search-input.sync="updateUsers"
              cache-items
              label="Condividi con"
              clearable
              multiple
              small-chips
              deletable-chips
            ></v-autocomplete>
            <div
              v-for="error in errors"
              :key="error"
              class="alert alert-danger red--text"
              role="alert"
            >
              Selezionare almeno un utente con cui condividere il file
            </div>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn color="primary" @click.prevent="saveUploadedFile" class="mr-1"
            >Aggiungi file</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-progress-linear
      v-if="progressValue !== null"
      :value="progressValue"
    ></v-progress-linear>
  </validation-observer>
</template>

<script>
import _ from "lodash";
import { required } from "vee-validate/dist/rules";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from "vee-validate";
import authMixin from "../../mixins/auth.mixin";

setInteractionMode("eager");

extend("required", required);
export default {
  mixins: [authMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      updateUsers: null,
      usersLoading: false,
    };
  },
  watch: {
    updateUsers: _.debounce(async function (val) {
      val && this.searchusers(val);
    }, 500),
  },
  methods: {
    fileValidation({ errors }) {
      if (errors.length) {
        return {
          on: ["change"],
        };
      }

      return {
        on: ["change"],
      };
    },
    onFileChange(files) {
      this.files = files;
    },
    async saveUploadedFile() {
      let isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      await this.$store.dispatch("filesView/uploadFileForm/save");
      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async searchusers(q) {
      this.usersLoading = true;
      try {
        await this.$store.dispatch(
          `filesView/uploadFileForm/users/search`,
          q && typeof q === "string" ? q : null
        );
      } catch (ex) {
        console.error(ex);
      } finally {
        this.usersLoading = false;
      }
    },
  },
  computed: {
    progressValue() {
      return this.$store.state.filesView.uploadFileForm.progressValue;
    },
    users() {
      return this.$store.getters["filesView/uploadFileForm/users/list"];
    },
    titolo: {
      get() {
        return this.$store.state.filesView.uploadFileForm.titolo;
      },
      set(value) {
        this.$store.commit("filesView/uploadFileForm/setTitolo", value);
      },
    },
    visibleTo: {
      get() {
        return this.$store.state.filesView.uploadFileForm.visibleTo;
      },
      set(value) {
        this.$store.commit("filesView/uploadFileForm/setVisibleTo", value);
      },
    },

    uploadedFiles: {
      get() {
        return this.$store.state.filesView.uploadFileForm.files;
      },
      set(value) {
        this.$store.commit("filesView/uploadFileForm/setFiles", value);
      },
    },
  },
};
</script>

<style>
</style>
