<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card v-if="file">
        <v-card-title class="headline grey lighten-2">
          Rimuovi file
        </v-card-title>

        <v-card-text>
          <p>
            Stai per eliminare il file
            <b>{{ file.displayName }}</b
            >. Confermi di voler procedere?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="dialog = false"> Annulla </v-btn>
          <v-btn color="primary" text @click="onSaveClick"> Conferma </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  methods: {
    async onSaveClick() {
      await this.$store.dispatch("filesView/deleteFile", this.file.id);
      this.dialog = false;
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.filesView.removeFileDialog;
      },
      set(value) {
        this.$store.commit("filesView/setRemoveFileDialog", value);
      },
    },
    file() {
      return this.$store.getters["filesView/files/current"];
    },
  },
};
</script>

<style>
</style>