const environments = {
  prod: "prod",
  dev: "development",
  current: process.env.VUE_APP_ENV || "prod",
};

export default {
  environments: {
    isDev() {
      return environments.current === environments.dev;
    },
  },
};
