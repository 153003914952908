<template>
  <v-container>
    <ShareWithModal />
    <DeleteFileModal />
    <uploadFileForm />
    <v-row class="mb-3">
      <v-col md="9">
        <h3>Elenco File</h3>
      </v-col>
      <v-col md="3">
        <v-text-field
          label="Cerca"
          v-model="searchFilter"
          type="text"
          class="form-control"
          name="searchFilter"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="tableParams"
      :server-items-length="totalItems"
      :loading="tableLoading"
      :headers="visibleColumns"
      :items="files"
      sort-by="createdOn"
      :sort-desc="true"
      @update:must-sort="getDataFromApi"
      @update:items-per-page="getDataFromApi"
      @update:page="getDataFromApi"
      @update:sort-by="getDataFromApi"
      @update:sort-desc="getDataFromApi"
      class="elevation-1"
    >
      <template v-slot:item.tipo="{ item }">
        <v-icon>{{ fileType(item.displayName) }}</v-icon>
      </template>
      <template v-slot:item.downloadURL="{ item }">
        <v-btn @click.prevent="onDownloadClick(item)" color="primary"
          >Download</v-btn
        >
      </template>
      <template v-slot:item.sharedWith="{ item }">
        <div class="pt-3 pb-3">
          <v-chip
            v-for="user in item.sharedWith"
            :key="user.id"
            color="primary"
            class="mr-1 mb-1"
            >{{ user.email }}</v-chip
          >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                x-small
                depressed
                @click.prevent="onEditUsers(item.id)"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> fa-pencil-alt </v-icon>
              </v-btn>
            </template>
            <span>Modifica utenti</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.remove="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              x-small
              depressed
              @click.prevent="onRemoveClick(item.id)"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> fa-trash </v-icon>
            </v-btn>
          </template>
          <span>Rimuovi</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _ from "lodash";
import globalHelpers from "../helpers/global.helpers";
import dateHelpers from "../helpers/dates.helpers";
import FileService from "../services/files.service";
import authMixin from "../mixins/auth.mixin";

import uploadFileForm from "../components/files/UploadFileForm";
import ShareWithModal from "../components/files/ShareWithModal";
import DeleteFileModal from "../components/files/DeleteFileModal";
export default {
  mixins: [authMixin],
  components: { uploadFileForm, ShareWithModal, DeleteFileModal },
  data() {
    return {
      updateUsers: null,
      usersLoading: false,
      shareWithModal: false,

      headers: [
        { text: "Data", value: "uploadDate" },
        { text: "Tipo", value: "tipo" },
        { text: "Nome File", value: "displayName" },
        { text: "Titolo", value: "title" },
        { text: "Download", value: "downloadURL", sortable: false },
        { text: "Caricato da", value: "email" },
        { text: "Condiviso con", value: "sharedWith", onlyAdmin: true },
        { text: "", value: "remove", sortable: false, onlyAdmin: true },
      ],
    };
  },
  computed: {
    visibleColumns() {
      return _.filter(this.headers, (c) => !c.onlyAdmin || this.isUserAdmin);
    },
    tableParams: {
      get() {
        return this.$store.state.filesView.tableParams;
      },
      set(value) {
        this.$store.commit("filesView/setTableParams", value);
      },
    },

    tableLoading: {
      get() {
        return this.$store.state.filesView.tableLoading;
      },
      set(value) {
        this.$store.commit("filesView/setTableLoading", value);
      },
    },

    totalItems() {
      return this.$store.state.filesView.files.totalItems;
    },
    files() {
      return this.$store.getters["filesView/files/list"];
    },
    user: {
      get() {
        return this.$store.state.filesView.user;
      },
      set(value) {
        this.$store.commit("filesView/setUser", value);
        this.getDataFromApi();
      },
    },
    users() {
      return this.$store.getters["filesView/users/list"];
    },
    searchFilter: {
      get() {
        return this.$store.state.filesView.searchFilter;
      },
      set(value) {
        this.$store.commit("filesView/setSearchFilter", value);
      },
    },
    titleFilter: {
      get() {
        return this.$store.state.filesView.titleFilter;
      },
      set(value) {
        this.$store.commit("filesView/setTitleFilter", value);
      },
    },
    emailFilter: {
      get() {
        return this.$store.state.filesView.emailFilter;
      },
      set(value) {
        this.$store.commit("filesView/setEmailFilter", value);
      },
    },
    sharedWithFilter: {
      get() {
        return this.$store.state.filesView.sharedWithFilter;
      },
      set(value) {
        this.$store.commit("filesView/setSharedWithFilter", value);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("filesView/files/resetItems");
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    searchFilter: _.debounce(async function (val) {
      await this.getDataFromApi();
    }, 500),
    updateUsers: _.debounce(async function (val) {
      val && this.searchusers(val);
    }, 500),
  },
  methods: {
    async onDownloadClick(item) {
      await this.$store.dispatch("filesView/downloadFile", {
        id: item.id,
        filename: item.displayName,
      });
    },
    onRemoveClick(itemId) {
      this.$store.commit("filesView/files/setCurrentId", itemId);
      this.$store.commit("filesView/setRemoveFileDialog", true);
      console.log("itemId", itemId);
    },
    onEditUsers(itemId) {
      this.$store.commit("filesView/files/setCurrentId", itemId);
      this.$store.commit("filesView/setAddUserDialog", true);
    },
    fileType(filename) {
      var split = filename.split(".");
      var icon = "file";
      switch (split[split.length - 1].toLowerCase()) {
        case "csv":
        case "xls":
        case "xlsx":
          icon = "file-excel";
          break;
        case "zip":
        case "rar":
          icon = "file-archive";
          break;
        case "mp3":
        case "wav":
        case "ogg":
          icon = "file-audio";
          break;
        case "jpg":
        case "bmp":
        case "png":
          icon = "file-image";
          break;
        case "jpeg":
        case "mp4":
        case "avi":
          icon = "file-video";
          break;
        case "ppt":
        case "pptx":
          icon = "file-powerpoint";
          break;
        case "doc":
        case "docx":
          icon = "file-word";
          break;
        case "pdf":
          icon = "file-pdf";
          break;
        default:
          break;
      }
      return "fa-" + icon;
    },

    async searchusers(fullName) {
      this.usersLoading = true;
      try {
        await this.$store.dispatch(`filesView/users/search`, {
          withRoles: [globalHelpers.roles.geoCollaborator],
          name: fullName && typeof fullName === "string" ? fullName : null,
        });
      } catch (ex) {
        console.error(ex);
      } finally {
        this.usersLoading = false;
      }
    },
    formatDate(date) {
      return dateHelpers.formatIsoDate(date);
    },
    async getDataFromApi() {
      await this.$store.dispatch("filesView/loadTableData");
    },
    async viewItem(item) {
      await FileService.download(item.id, item.uploadedFileName);
    },
    onResetClick() {
      this.$store.commit("filesView/setFiltersData", null);
      this.getDataFromApi();
    },
    async deleteItem(uploadedFile) {
      await this.$store.dispatch("filesView/delete", uploadedFile.id);
    },
  },
};
</script>

<style>
</style>
